import { PulldownResponse } from "./../../models/response/PulldownResponse";
import { VueConstructor } from "vue";
import axios from "axios";
import { $loading, $unloading } from "../loading/loading";
import { $alert } from "@/plugins/alert/alert";
import store from "@/store/index";
import { Paths } from "./paths";

const baseURL = process.env.VUE_APP_API_BASE_URL;
// const BaseURL = "https://www.hgswp01.com/aries/api";
// const BaseURL = "https://pmservice-service-webat.com/aries/api";

function ErrorHandle(error: unknown) {
  let errorMeaasage = "エラー";
  //axiosエラーだった場合
  if (axios.isAxiosError(error)) {
    if (error.response?.data?.message) {
      console.error(error);
      $alert("error", error.response?.data?.message);
      return;
    }
    if (error.code === "ECONNABORTED") {
      errorMeaasage = "タイムアウト";
    } else {
      switch (error.response?.status) {
        case 401:
          errorMeaasage = "401エラー";
          break;
        case 403:
          errorMeaasage = "403エラー";
          break;
        case 404:
          errorMeaasage = "404エラー";
          break;
        case 500:
          errorMeaasage = "500エラー";
          break;
        case 503:
          errorMeaasage = "503エラー";
          break;
        default:
          errorMeaasage = "予期せぬエラーが発生しました。";
      }
    }
    console.error(error);
    $alert("error", errorMeaasage);
    return;
  }
  if (error instanceof Error) {
    errorMeaasage = "予期せぬエラーが発生しました。";
    console.error(error);
    $alert("error", errorMeaasage);
    return;
  }
}

export const axiosClient = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json"
  }
});
axiosClient.interceptors.request.use(config => {
  $loading();
  config.headers["authorization"] = store.state.token;
  config.headers["UserAuthorization"] = store.state.mtoken;
  return config;
});
axiosClient.interceptors.response.use(
  response => {
    $unloading();
    return response;
  },
  error => {
    $unloading();
    ErrorHandle(error);
  }
);

function getUrl(baseUrl: string, params?: string | string[]): string {
  const param = params ? params : "";
  const url = param
    ? baseUrl + (baseUrl.match("^.*/$") ? "" : "?") + param
    : baseUrl + (baseUrl.match("^.*/$") ? "" : "/");
  return url;
}

/**
 * Get Method
 * @param {String} path
 * @param {String|String[]} params
 * @param {Object} query
 */
export const $get = async <T>(
  path: string,
  params?: string | string[],
  query?: unknown
): Promise<T | undefined> => {
  const url = getUrl(path, params);
  const result = await axiosClient.get<T>(url, { params: query });
  return result?.data;
};

/**
 * POST Method
 * @param {String} path
 * @param {String|String[]} params
 */
export const $post = async <T>(
  path: string,
  params?: unknown
): Promise<T | undefined> => {
  const response = await axiosClient.post<T>(path, params);
  return response?.data;
};

/**
 * PUT Method
 * @param path
 * @param params
 */
export const $put = async <T>(
  path: string,
  params?: unknown
): Promise<T | undefined> => {
  const response = await axiosClient.put<T>(path, params);
  return response?.data;
};

/**
 * delete
 * @param path
 * @param data
 */
export const $delete = async <T>(
  path: string,
  data?: Record<string | number | symbol, unknown>
): Promise<T | undefined> => {
  const response = await axiosClient.delete<T>(path, { data });
  return response?.data;
};

const CodeKbn = [
  "SALARY",
  "OVDAY",
  "WWS",
  "EXISTE",
  "SEX",
  "AUTH",
  "ZAIKBN"
] as const;
export type CodeKbn = typeof CodeKbn[number];
const $code = async (kbn: CodeKbn) => {
  const path = Paths.codeMasterPulldown;
  const response = await axiosClient.get<PulldownResponse[]>(path, {
    params: { query: { kbn } }
  });
  return response.data;
};

declare module "vue/types/vue" {
  interface Vue {
    $post<T>(path: string, params?: unknown): Promise<T | undefined>;
    $get<T>(
      path: string,
      params?: string | string[],
      query?: unknown
    ): Promise<T | undefined>;
    $put<T>(path: string, params?: unknown): Promise<T | undefined>;
  }
}
export const api = {
  install(Vue: VueConstructor) {
    Vue.prototype.$get = $get;
    Vue.prototype.$post = $post;
  }
};

export const useApi = () => {
  return {
    $get,
    $post,
    $put,
    $delete,
    $code,
    Paths
  };
};
