export default [
  // {
  //   to: "/test",
  //   icon: "mdi-briefcase-clock",
  //   text: "テスト",
  //   authority: [0, 1, 2, 4, 5, 9]
  // },
  {
    to: "/office",
    icon: "mdi-briefcase-clock",
    text: "勤怠登録",
    authority: [0]
  },
  {
    to: "/mainhome",
    icon: "mdi-home",
    text: "ホーム",
    authority: [1, 2, 4, 5, 8, 9]
  },
  {
    to: "/attendance",
    icon: "mdi-file-table",
    text: "勤務表",
    authority: [1, 2, 4, 5, 8, 9]
  },
  {
    to: "/shifttable",
    icon: "mdi-chart-timeline",
    text: "シフト表",
    authority: [1, 2, 4, 5, 8, 9]
  },
  {
    to: "/approval-status",
    icon: "mdi-file-table",
    text: "承認処理",
    authority: [1, 2, 3, 4, 5, 8, 9]
  },
  // {
  //   to: "/check-approval",
  //   icon: "mdi-file-table",
  //   text: "承認確認",
  //   authority: [8, 9]
  // },
  // {
  //   to: "/list/shift-pattern",
  //   icon: "mdi-tune",
  //   text: "シフトパターン",
  //   authority: [2, 5, 8, 9]
  // },
  // {
  //   to: "/list/daily-event",
  //   icon: "mdi-calendar-month-outline",
  //   text: "イベント",
  //   authority: [1, 2, 3, 4, 5, 9]
  // },
  // {
  //   to: "/shift-order",
  //   icon: "mdi-order-numeric-ascending",
  //   text: "シフト表示順",
  //   authority: [1, 2, 3, 4, 5, 9]
  // },
  // {
  //   to: "/shift-pattern-order",
  //   icon: "mdi-order-numeric-descending",
  //   text: "パターン表示順",
  //   authority: [1, 2, 3, 4, 5, 9]
  // },
  // {
  //   to: "/AlertDetailList",
  //   icon: "mdi-alert",
  //   text: "未申請一覧",
  //   authority: [1, 2, 4, 5, 8, 9]
  // },
  // {
  //   to: "/for-calc",
  //   icon: "mdi-calculator",
  //   text: "月集計状況確認",
  //   authority: [1, 2, 4, 5, 8, 9]
  // },
  // {
  //   to: "/affiliation-summary",
  //   icon: "mdi-calculator",
  //   text: "施設集計一覧",
  //   authority: [2, 4, 5, 8, 9]
  // },
  // {
  //   to: "/authinfo",
  //   icon: "mdi-account-multiple",
  //   text: "ログインアカウント",
  //   authority: [5, 8, 9]
  // },
  // {
  //   to: "/self",
  //   icon: "mdi-account",
  //   text: "アカウント情報",
  //   authority: [1, 2, 4, 5, 8]
  // },
  // {
  //   open: false,
  //   text: "マスタメンテ",
  //   icon: "mdi-database-cog",
  //   authority: [5, 9],
  //   children: [
  //     {
  //       to: "/list/code-master",
  //       icon: "mdi-database",
  //       text: "コードマスタ",
  //       authority: [5, 9]
  //     },
  //     {
  //       to: "/list/shozoku-exp",
  //       icon: "mdi-database",
  //       text: "所属拡張マスタ",
  //       authority: [9]
  //     },
  //     {
  //       to: "/list/shain-exp",
  //       icon: "mdi-database",
  //       text: "社員拡張マスタ",
  //       authority: []
  //     },
  //     {
  //       to: "/shift-lock",
  //       icon: "mdi-lock",
  //       text: "シフト表ロック",
  //       authority: []
  //     },
  //     {
  //       to: "/list/shozoku",
  //       icon: "mdi-database",
  //       text: "所属グループマスタ",
  //       authority: []
  //     },
  //     {
  //       //to: "/list/shain",
  //       to: "/user-master",
  //       icon: "mdi-database",
  //       text: "社員マスタ",
  //       authority: [5, 9]
  //     },
  //     {
  //       to: "/approval",
  //       icon: "mdi-file-table",
  //       text: "給与承認者設定",
  //       authority: [5, 9]
  //     },
  //     {
  //       to: "/approvalpetition",
  //       icon: "mdi-file-table",
  //       text: "申請承認者設定",
  //       authority: [5, 9]
  //     },
  //     {
  //       to: "/pca-exportmaster",
  //       icon: "mdi-account-multiple",
  //       text: "マスタ連携",
  //       authority: [5, 9]
  //     },
  //     {
  //       to: "/list/basemaster",
  //       icon: "mdi-database",
  //       text: "基本情報マスタ",
  //       authority: [5, 9]
  //     },
  //     {
  //       to: "/list/shozokumaster",
  //       icon: "mdi-database",
  //       text: "所属マスタ",
  //       authority: [5, 9]
  //     }
  //   ]
  // },
  // {
  //   to: "/pca-cooperation",
  //   icon: "mdi-account-multiple",
  //   text: "給与連携",
  //   authority: [5, 9]
  // },
  {
    open: false,
    text: "QRコード発行",
    icon: "mdi-qrcode-scan",
    authority: [5, 8, 9],
    children: [
      {
        to: "/qr-shozoku",
        icon: "mdi-qrcode",
        text: "所属QRコード",
        authority: [5, 8, 9]
      },
      {
        to: "/qr-shain",
        icon: "mdi-qrcode",
        text: "社員QRコード",
        authority: [5, 8, 9]
      }
    ]
  },
  {
    open: false,
    text: "システム管理",
    icon: "mdi-account-cowboy-hat",
    authority: [9],
    children: [
      {
        to: "/list/code-group",
        icon: "mdi-database",
        text: "コードグループ",
        authority: [9]
      },
      {
        text: "デバッグ",
        icon: "mdi-vuejs",
        to: "/debug",
        authority: [9]
      }
    ]
  },
  {
    text: "ログアウト",
    icon: "mdi-logout",
    to: "/login",
    authority: [0, 1, 2, 3, 4, 5, 8, 9]
  }
];
